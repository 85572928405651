import {http} from 'src/services/http'

export async function addToFavoriteApi(propertyId: number) {
  const data = await http.post(`/favorite/${propertyId}`)

  return data
}

export async function removeFromFavoritesApi(propertyId: number) {
  const data = await http.delete(`/favorite/${propertyId}`)

  return data
}

export async function getFavoritePropertiesApi(
  page = 1,
  limit = 20,
  photosLimit: number | null = null,
  imageMaxWidth: number | null = null,
) {
  const data = await http.get(`/favorites`, {
    params: {
      page: page,
      limit: limit,
      image_max_width: imageMaxWidth,
      photos_limit: photosLimit,
    },
  })

  return data
}

export async function getFavoritePropertyIdsApi() {
  const data = await http.get(`/favorites/ids`)

  return data
}

export async function isFavoritePropertyApi(propertyId: number) {
  const {data} = await http.get(`/favorite/${propertyId}/is_favorite`)

  return {data}
}
