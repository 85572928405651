import {makeStyles} from '@material-ui/core/styles'
import AppButton from 'src/components/elements/buttons/AppButton'
import {onChangeIntegerHandler} from 'src/utils/dom'
import EnhancedInput from 'src/components/forms/textField/EnhancedInput'
import clsx from 'clsx'
import AppIcon from '../elements/icons/AppIcon'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, palette}) => ({
  button: {
    height: spacing(46 / 8),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  text: {
    '& input': {
      border: 'none !important',
      width: '24px',
      height: spacing(46 / 8),
      textAlign: 'center',
      padding: 0,
      '&:focus': {
        border: `1px solid ${palette.divider} !important`,
        borderRadius: `${spacing(1)}px !important`,
      },
    },
    /* Chrome, Safari, Edge, Opera */
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    /* Firefox */
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  icon: {
    fontSize: 15,
    color: palette.secondary.main,
  },
}))

export default function Counter(props: {
  handleDecrement: () => void
  handleIncrement: () => void
  handleChange: (value: number) => void
  value: number
  disabled?: boolean
  canIncrease?: boolean
}) {
  const classes = useStyles()

  const {
    handleDecrement,
    handleIncrement,
    handleChange,
    value,
    disabled = false,
    canIncrease = true,
  } = props

  return (
    <div className="flex flex-row justify-start items-center gap-1 h-3">
      <AppButton
        onClick={handleDecrement}
        className={clsx(
          classes.button,
          disabled ? classes.disabled : undefined,
        )}
      >
        <AppIcon name="minus" />
      </AppButton>
      <EnhancedInput
        disabled={disabled}
        className={classes.text}
        value={value}
        type="number"
        onChange={onChangeIntegerHandler<number>(handleChange, 0)}
      />
      <AppButton
        onClick={handleIncrement}
        className={clsx(
          classes.button,
          disabled || !canIncrease ? classes.disabled : undefined,
        )}
      >
        <AppIcon name="plus" />
      </AppButton>
    </div>
  )
}
