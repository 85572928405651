import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'next-i18next'
import {
  addToFavoriteApi,
  getFavoritePropertyIdsApi,
  isFavoritePropertyApi,
  removeFromFavoritesApi,
} from 'src/services/api/favorite'
import {useIsLoggedIn} from './user'
import {useCurrentLocale} from './locale'
import {getInternetTypeInfoOther} from 'src/utils/property'
import {PropertyCardProps, PropertyProps} from 'src/types/property'
import {PropertyAddressProps} from 'src/types/address'
import {DEFAULT_LOCALE, LOCALE_EN, LOCALE_FR} from 'src/constants/locale'
import {LocaleType} from 'src/types/utils'
import {StatDataProps} from 'src/components/modules/admin/stats/data'
import {SearchBookingPropertyProps} from 'src/types/booking'
import {formatUrl, updateExceptionalStringsInUrl} from 'src/utils/breadcrumb'
import {
  BREAD_CRUMB_CHALETS_A_LOUER,
  BREAD_CRUMB_COTTAGE_RENTAL,
} from 'src/constants/breadcrumb'
import {BreadcrumbProps} from 'src/types/breadcrumb'
import {ucFirst} from 'src/utils/other'

type FavoriteIdMap = Map<number, boolean>

export function useFavoritePropertyIds() {
  const isLoggedIn = useIsLoggedIn()

  const [favoritePropertyIds, setFavoritePropertyIds] = useState<
    Map<number, boolean>
  >(new Map())

  useEffect(() => {
    if (isLoggedIn) {
      getFavoritePropertyIdsApi().then(({data}) => {
        let idsMap: FavoriteIdMap = data.ids.reduce(
          (acc: FavoriteIdMap, id: number) => {
            acc.set(id, true)
            return acc
          },
          new Map(),
        )

        setFavoritePropertyIds(idsMap)
      })

      return
    }

    setFavoritePropertyIds(new Map())
  }, [isLoggedIn])

  return {
    favoritePropertyIds,
  }
}

export function useIsFavorite(propertyId: number) {
  const isLoggedIn = useIsLoggedIn()

  const [isFavorite, setIsFavorite] = useState(false)

  useEffect(() => {
    if (!propertyId) {
      return
    }

    if (isLoggedIn) {
      isFavoritePropertyApi(propertyId)
        .then((res) => setIsFavorite(res.data.is_favorite))
        .catch(() => setIsFavorite(false))

      return
    }

    setIsFavorite(false)
  }, [isLoggedIn, propertyId])

  const onFavoriteClick = useCallback(() => {
    if (!propertyId) {
      return
    }

    if (!isLoggedIn) {
      return
    }

    if (isFavorite) {
      setIsFavorite(false) //so ui update now
      removeFromFavoritesApi(propertyId)

      return
    }

    setIsFavorite(true) //so ui update now
    addToFavoriteApi(propertyId)
  }, [isLoggedIn, isFavorite, propertyId])

  return {
    isFavorite,
    onFavoriteClick,
  }
}

export function getPropertyName(
  property:
    | PropertyProps
    | SearchBookingPropertyProps
    | PropertyCardProps
    | StatDataProps
    | null
    | undefined,
  locale: LocaleType,
  includeSubName?: boolean,
) {
  if (typeof includeSubName === 'undefined') {
    includeSubName = true
  }
  if (!property) {
    return ''
  }

  let name = property.name_fr
  let sub_name = property.sub_name_fr

  if (locale === LOCALE_EN) {
    name = property.name_en ? property.name_en : name
    sub_name = property.sub_name_en ? property.sub_name_en : sub_name
  }

  if (includeSubName && sub_name) {
    return `${name} | ${sub_name}`
  }

  return name
}

export function _usePropertyAddress(
  propertyAddress: PropertyAddressProps,
  showState = true,
): string {
  let addresses: string[] = []
  if (propertyAddress.city) {
    addresses.push(propertyAddress.city)
  }

  //make sure mrc exist and not the same name as city
  if (propertyAddress.mrc && propertyAddress.mrc !== propertyAddress.city) {
    addresses.push(propertyAddress.mrc)
  }

  //make sure administrative_area exist and not the same name as mrc
  if (
    propertyAddress.administrative_area &&
    propertyAddress.administrative_area !== propertyAddress.mrc
  ) {
    addresses.push(propertyAddress.administrative_area)
  }

  if (propertyAddress.state && showState) {
    if (propertyAddress.state === 'QC') {
      addresses.push('Québec')
    } else {
      addresses.push(propertyAddress.state)
    }
  }

  if (propertyAddress.country !== 'CA') {
    addresses.push(propertyAddress.country)
  }

  return addresses.join(', ')
}

export function usePropertyFullAddress(
  property: PropertyProps | undefined | null,
) {
  const currentLocale = useCurrentLocale()
  if (!property) {
    return ''
  }

  const propertyAddress = property.addresses.find(
    (a) => a.locale === currentLocale,
  )
  if (!propertyAddress) {
    return ''
  }

  if (propertyAddress.full_address) {
    return propertyAddress.full_address
  }

  return _usePropertyAddress(propertyAddress)
}

export function usePropertyAddress(
  property: {
    addresses: PropertyAddressProps[]
  },
  showState = true,
) {
  const currentLocale = useCurrentLocale()

  const propertyAddress = property.addresses.find(
    (a) => a.locale === currentLocale,
  )
  if (!propertyAddress) {
    return ''
  }

  return _usePropertyAddress(propertyAddress, showState)
}

export function usePropertyCanAcceptAnimals(rules?: string[] | null) {
  if (!rules) {
    return true
  }

  if (rules.length < 3) {
    return true
  }

  return rules[3] === 'pets_allowed'
}

export function useInternetType(info: string | undefined | null) {
  const {t} = useTranslation('search')
  const currentLocale = useCurrentLocale()

  if (!info) {
    return ''
  }

  return getInternetTypeInfoOther(info, currentLocale, t)
}

export function useReviewInfo(count: number) {
  const {t} = useTranslation('common')

  if (count) {
    return `(${count} ${t('reviews')})`
  }

  return t('New')
}

//todo:Alex add traductions
export function useRoomName(roomName: string) {
  const currentLocale = useCurrentLocale()

  if (roomName.startsWith('Room #') && currentLocale === LOCALE_FR) {
    return `Chambre ${roomName.substring(5)}`
  }

  if (roomName.startsWith('Chambre #') && currentLocale === LOCALE_EN) {
    return `Room ${roomName.substring(8)}`
  }

  if (roomName.startsWith('Open room #') && currentLocale === LOCALE_FR) {
    return `Chambre à aire ouverte ${roomName.substring(10)}`
  }

  if (
    roomName.startsWith('Chambre à aire ouverte #') &&
    currentLocale === LOCALE_EN
  ) {
    return `Open room ${roomName.substring(23)}`
  }

  return roomName
}

export function usePropertyBreadcrumbs(property: PropertyProps) {
  const {t} = useTranslation('common')
  const currentLocale = useCurrentLocale()
  const homePageLink =
    currentLocale === DEFAULT_LOCALE ? '' : `${currentLocale}/`

  let route = `${homePageLink}${formatUrl(
    currentLocale === DEFAULT_LOCALE
      ? BREAD_CRUMB_CHALETS_A_LOUER
      : BREAD_CRUMB_COTTAGE_RENTAL,
  )}`
  const breadcrumbs: BreadcrumbProps[] = [
    {
      text: t('Home'),
      url: homePageLink,
    },
    {
      text:
        currentLocale === DEFAULT_LOCALE
          ? BREAD_CRUMB_CHALETS_A_LOUER
          : BREAD_CRUMB_COTTAGE_RENTAL,
      url: route,
    },
  ]

  const propertyBreadcrumbs = property.breadcrumbs
  const hasBreadcrumbs = Boolean(propertyBreadcrumbs.length)
  if (hasBreadcrumbs) {
    for (const breadcrumb of propertyBreadcrumbs) {
      route = `${route}/${formatUrl(breadcrumb.title)}`
      breadcrumbs.push({
        text: ucFirst(breadcrumb.title) ?? '',
        url: updateExceptionalStringsInUrl(route),
      })
    }
  }

  const address =
    property.addresses.find(
      (a: PropertyAddressProps) => a.locale === currentLocale,
    ) || null
  if (!hasBreadcrumbs && address?.administrative_area) {
    route = `${route}/${formatUrl(address.administrative_area)}`
    breadcrumbs.push({
      text: address.administrative_area,
      url: updateExceptionalStringsInUrl(route),
    })
  }
  if (!hasBreadcrumbs && address?.city) {
    route = `${route}/${formatUrl(address.city)}`
    breadcrumbs.push({
      text: address.city,
      url: updateExceptionalStringsInUrl(route),
    })
  }
  if (!hasBreadcrumbs && !address?.city && address?.mrc) {
    route = `${route}/${formatUrl(address.mrc)}`
    breadcrumbs.push({
      text: address.mrc,
      url: updateExceptionalStringsInUrl(route),
    })
  }

  let propertyName = property.name_fr

  if (currentLocale === LOCALE_EN) {
    propertyName = property.name_en ? property.name_en : propertyName
  }

  breadcrumbs.push({
    text: propertyName,
    url: updateExceptionalStringsInUrl(`${route}/${property.handle}`),
  })

  return breadcrumbs
}
